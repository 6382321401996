/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   ____  ____   ___  
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| |  _ \|  _ \ / _ \ 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || |  | |_) | |_) | | | |
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |  |  __/|  _ <| |_| |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___| |_|   |_| \_\\___/ 
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI Dashboard PRO - v1.0.0
=========================================================

* Product Page: https://www.horizon-ui.com/pro/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import { Flex,useToast } from "@chakra-ui/react";
import Card from "components/card/Card";
import React, { useState, useEffect } from "react";
import SearchTableStandoutAmenities from "views/admin/main/standout-amenities/overview/components/SearchTableStandoutAmenitiesOverivew";
import { columnsDataStandoutAmenitiesOverview } from "views/admin/main/standout-amenities/overview/variables/columnsDataStandoutAmenitiesOverview";
import { API_BASE_URL } from "constants/apiConstants";
import axios from "axios";

export default function StandoutAmenitiesOverview() {
  const [standoutAmenities,setStandoutAmenities] = useState([]);
  const toast = useToast()

  const fetchStandoutAmenities = ()  => {
    axios.get(API_BASE_URL+'get/all/standout/amenity',{
           headers: {
             Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
           }
        })
      .then((response) => {
        setStandoutAmenities(response.data.data)
      })
      .catch(function (error){
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
  }

  useEffect(()=> {
    fetchStandoutAmenities()
  },[]);

  return (
    <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
      <Card px='0px'>
        <SearchTableStandoutAmenities
          tableData={standoutAmenities}
          columnsData={columnsDataStandoutAmenitiesOverview}
        />
      </Card>
    </Flex>
  );
}
