import {
  Avatar,
  Button,
  Flex,
  Icon,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Stack,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Select,
} from "@chakra-ui/react";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import React, { useMemo,useState,useEffect } from "react";
import { MdChevronRight, MdChevronLeft, MdEdit, MdBlock, MdDelete } from "react-icons/md";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { useNavigate,Link } from "react-router-dom";
import { ASSETS_URL } from "constants/apiConstants";
import axios from "axios";
import { API_BASE_URL } from "constants/apiConstants";
import InputField from 'components/fields/InputField';

function SearchTable2(props) {
  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const toast = useToast()
  const cancelRef = React.useRef()
  const [isOpen, setIsOpen] = React.useState()
  const [eventType, setEventType] = useState();
  const [propertyId, setPropertyId] = useState();
  const [confirmBoxMessage, setConfirmBoxMessage] = useState();
  const [confirmBoxTitle, setConfirmBoxTitle] = useState();

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setGlobalFilter,
    state,
    setPageSize
  } = tableInstance;

  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const { pageIndex, pageSize } = state;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const brandColor = useColorModeValue("brand.500", "brand.400");

  const navigate = useNavigate();
  const addPropertyClick = () => {
    navigate("/admin/properties/new-property");
  }

  const onClose = () => setIsOpen(false)

  const openHidePropertyConfirmBox = (id,type) => {
    setConfirmBoxTitle("Hide Property");
    setConfirmBoxMessage("are you sure want to hide this property?");
    setIsOpen(true);
    setPropertyId(id);
    setEventType(type);
  }

  const openDeletePropertyConfirmBox = (id,type) => {
    setConfirmBoxTitle("Delete Property");
    setConfirmBoxMessage("Are you sure you want your delete this listing? This listing will be deleted permanently. This action can’t be undone.");
    setIsOpen(true);
    setPropertyId(id);
    setEventType(type);
  }

  const eventHandle = () => {
    setIsOpen(false);
    const payload = {
      'id':propertyId
    };

    if(eventType === 1){
      axios.post(API_BASE_URL+'hide/property',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
        window.location.reload();
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }else if(eventType === 2){
      axios.post(API_BASE_URL+'delete/property',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
        window.location.reload();
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }
  }

  const updatePriority = (id,value) => {
      const payload = {
        'propertyId': id,
        'priority': value
      }
      axios.post(API_BASE_URL+'update/property/priority',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then(function (response){
        if(response.data.apistatus === true){
          toast({ title: response.data.message,status: 'success',isClosable: true,position: 'top'})
        }else if(response.data.apistatus === false){
          toast({ title: response.data.message,status: 'error',isClosable: true,position: 'top'})
        }
      })
      .catch(function (error){
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
  }

  useEffect(() => {
    setPageSize(20);
  },[]);

  return (
    <>
      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize='lg' fontWeight='bold'>
              {confirmBoxTitle}
            </AlertDialogHeader>

            <AlertDialogBody>
              {confirmBoxMessage}
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme='red' onClick={eventHandle} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
          
        <div className="search-box">
          <Select
            w={{ lg: "70px" }}
            fontSize='sm'
            variant='main'
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}>
            <option value='10'>10</option>
            <option value='20'>20</option>
            <option value='30'>30</option>
            <option value='40'>40</option>
            <option value='50'>50</option>
          </Select>
          <SearchBar
            onChange={(e) => setGlobalFilter(e.target.value)}
            h='44px'
            w={{ lg: "390px" }}
            borderRadius='16px'
            className="search-box"
          />
        </div>

        <div className="button_pos">
          <Button variant="action" onClick={addPropertyClick}>Add</Button>
        </div>
        
        <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px' gridRow={50}>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='10px'
                    key={index}
                    borderColor={borderColor}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      {column.render("Header")}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "ACTIONS") {
                      data = (
                          <Flex cursor='pointer' mx="auto">
                            <Link to={`/admin/properties/edit-property/${cell.value}`}>
                              <Icon color='secondaryGray.500' as={MdEdit} w='20px' h='20px' />
                            </Link>

                            <Link onClick={e => openHidePropertyConfirmBox(cell.value,1)}>
                              <Icon color='secondaryGray.500' as={MdBlock} w='20px' h='20px'/>
                            </Link>

                            <Link onClick={e => openDeletePropertyConfirmBox(cell.value,2)}>
                              <Icon color='secondaryGray.500' as={MdDelete} w='20px' h='20px' />
                            </Link>
                          </Flex>
                      );
                    }
                    if (cell.column.Header === "Priority") {
                      data = (
                        <NumberInput 
                          size='sm' 
                          maxW={100}  
                          onKeyUp={e => updatePriority(cell.row.original.p_id,e.target.value)} 
                          defaultValue={cell.value}
                          placeholder="Priority"
                          className="property-priority-input"
                        >
                        <NumberInputField />
                        </NumberInput>

                        // <InputField
                        //   type="NumberInput"
                        //   id="property-priority"
                        //   placeholder="Priority"
                        //   name="property_priority"
                        //   mb="0px"
                        //   className="property-priority-input"
                        //   onKeyUp={e => updatePriority(cell.row.original.p_id,e.target.value)}
                        //   defaultValue={cell.value}
                        // />
                      );
                    }
                    else if (cell.column.Header === "Id") {
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Image") {
                      data = (
                        <Flex align='center'>
                          <Avatar
                            src={ASSETS_URL+"properties/media/small/"+cell.value}
                            h='60px'
                            w='60px'
                            me='10px'
                          />
                        </Flex>
                      );
                    }else if (cell.column.Header === "Title") {
                      data = (
                        <Text
                          color={textColor}
                          fontSize='md'
                          fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    }else if (cell.column.Header === "Type") {
                      data = (
                        <Text
                          color={textColor}
                          fontSize='md'
                          fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Location") {
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    }else if (cell.column.Header === "Agent") {
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    }else if (cell.column.Header === "Developer") {
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Square feet") {
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Square meter") {
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Amount") {
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Currency") {
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    } else if (cell.column.Header === "Created At") {
                      data = (
                        <Text color={textColor} fontSize='md' fontWeight='500'>
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor={borderColor}>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        {/* <Flex
          direction={{ sm: "column", md: "row" }}
          justify='space-between'
          align='center'
          w='100%'
          px={{ md: "22px" }}>
          <Flex align='center'>
            <Text
              me='10px'
              minW='max-content'
              fontSize='sm'
              color='gray.500'
              fontWeight='normal'>
              Show rows per page
            </Text>
            <Select
              fontSize='sm'
              variant='main'
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}>
              <option value='10'>10</option>
              <option value='20'>20</option>
              <option value='30'>30</option>
              <option value='40'>40</option>
              <option value='50'>50</option>
            </Select>
          </Flex>
          <Stack direction='row' alignSelf='flex-end' spacing='4px' ms='auto'>
            <Button
              variant='no-effects'
              onClick={() => previousPage()}
              transition='all .5s ease'
              w='40px'
              h='40px'
              borderRadius='50%'
              bg='transparent'
              border='1px solid'
              borderColor={useColorModeValue("gray.200", "white")}
              display={
                pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"
              }
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}>
              <Icon as={MdChevronLeft} w='16px' h='16px' color={textColor} />
            </Button>
            {pageSize === 5 ? (
              <NumberInput
                max={pageCount - 1}
                min={1}
                w='75px'
                mx='6px'
                defaultValue='1'
                onChange={(e) => gotoPage(e)}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant='no-effects'
                    transition='all .5s ease'
                    onClick={() => gotoPage(pageNumber - 1)}
                    w='40px'
                    h='40px'
                    borderRadius='50%'
                    bg={
                      pageNumber === pageIndex + 1 ? brandColor : "transparent"
                    }
                    border={
                      pageNumber === pageIndex + 1
                        ? "none"
                        : "1px solid lightgray"
                    }
                    _hover={
                      pageNumber === pageIndex + 1
                        ? {
                            opacity: "0.7",
                          }
                        : {
                            bg: "whiteAlpha.100",
                          }
                    }
                    key={index}>
                    <Text
                      fontSize='sm'
                      color={pageNumber === pageIndex + 1 ? "#fff" : textColor}>
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              variant='no-effects'
              onClick={() => nextPage()}
              transition='all .5s ease'
              w='40px'
              h='40px'
              borderRadius='50%'
              bg='transparent'
              border='1px solid'
              borderColor={useColorModeValue("gray.200", "white")}
              display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}>
              <Icon as={MdChevronRight} w='16px' h='16px' color={textColor} />
            </Button>
          </Stack>
        </Flex> */}
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify='space-between'
          align='center'
          w='100%'
          px={{ md: "22px" }}>
          <Text
            fontSize='sm'
            color='gray.500'
            fontWeight='normal'
            mb={{ sm: "24px", md: "0px" }}>
            Showing {pageSize * pageIndex + 1} to{" "}
            {pageSize * (pageIndex + 1) <= tableData.length
              ? pageSize * (pageIndex + 1)
              : tableData.length}{" "}
            of {tableData.length} entries
          </Text>
          <Stack direction='row' alignSelf='flex-end' spacing='4px' ms='auto'>
            <Button
              variant='no-effects'
              onClick={() => previousPage()}
              transition='all .5s ease'
              w='40px'
              h='40px'
              borderRadius='50%'
              bg='transparent'
              border='1px solid'
              borderColor={useColorModeValue("gray.200", "white")}
              display={
                pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"
              }
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}>
              <Icon as={MdChevronLeft} w='16px' h='16px' color={textColor} />
            </Button>
            {pageSize === 5 ? (
              <NumberInput
                max={pageCount - 1}
                min={1}
                w='75px'
                mx='6px'
                defaultValue='1'
                onChange={(e) => gotoPage(e)}>
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant='no-effects'
                    transition='all .5s ease'
                    onClick={() => gotoPage(pageNumber - 1)}
                    w='40px'
                    h='40px'
                    borderRadius='50%'
                    bg={
                      pageNumber === pageIndex + 1 ? brandColor : "transparent"
                    }
                    border={
                      pageNumber === pageIndex + 1
                        ? "none"
                        : "1px solid lightgray"
                    }
                    _hover={
                      pageNumber === pageIndex + 1
                        ? {
                            opacity: "0.7",
                          }
                        : {
                            bg: "whiteAlpha.100",
                          }
                    }
                    key={index}>
                    <Text
                      fontSize='sm'
                      color={pageNumber === pageIndex + 1 ? "#fff" : textColor}>
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              variant='no-effects'
              onClick={() => nextPage()}
              transition='all .5s ease'
              w='40px'
              h='40px'
              borderRadius='50%'
              bg='transparent'
              border='1px solid'
              borderColor={useColorModeValue("gray.200", "white")}
              display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
              _hover={{
                bg: "whiteAlpha.100",
                opacity: "0.7",
              }}>
              <Icon as={MdChevronRight} w='16px' h='16px' color={textColor} />
            </Button>
          </Stack>
        </Flex>
      </Flex>
    </>
  );
}

export default SearchTable2;
