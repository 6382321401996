export const columnsDataStandoutAmenitiesOverview = [
  {
    Header: "ACTIONS",
    accessor: "rowId",
  },
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Title",
    accessor: "name",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Created At",
    accessor: "date",
  },
];
  