export const columnsDataPropertyTypesOverview = [
  {
    Header: "ACTIONS",
    accessor: "id",
  },
  {
    Header: "ID",
    accessor: "value",
  },
  {
    Header: "Title",
    accessor: "label",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Created At",
    accessor: "date",
  },
];
  