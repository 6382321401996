export const columnsDataPropertiesOverview = [
    {
        Header: "ACTIONS",
        accessor: "id",
    },
    {
        Header: "Priority",
        accessor: "priority",
    },
    {
        Header: "Id",
        accessor: "property_id",
    },
    {
        Header: "Image",
        accessor: "cover_photo",
    },
    {
        Header: "Title",
        accessor: "title",
    },
    {
        Header: "Type",
        accessor: "listing_type",
    },
    {
        Header: "Location",
        accessor: "location_title",
    },
    {
        Header: "Agent",
        accessor: "user.name",
    }, 
    {
        Header: "Developer",
        accessor: "developer.name",
    },
    {
        Header: "Square feet",
        accessor: "square_feet",
    },
    {
        Header: "Square meter",
        accessor: "square_meter",
    },
    {
        Header: "Amount",
        accessor: "amount",
    },
    {
        Header: "Currency",
        accessor: "currency",
    },
    {
        Header: "Created At",
        accessor: "date",
    },
    
];
    