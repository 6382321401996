import React from 'react';

import { Icon } from '@chakra-ui/react';
import { MdDashboard, MdHome, MdLock, MdOutlineShoppingCart,MdDomain,MdStore,MdPerson,MdOutlineHistoryToggleOff } from 'react-icons/md';
import { FaHandshake } from "react-icons/fa6";
import { HiLightBulb } from "react-icons/hi";
import { MdAccountCircle } from "react-icons/md";
import { FaUserTie } from "react-icons/fa";


// Admin Imports
import DashboardsDefault from 'views/admin/dashboards/default';
import DashboardsRTLDefault from 'views/admin/dashboards/rtl';
import DashboardsCarInterface from 'views/admin/dashboards/carInterface';
import DashboardsSmartHome from 'views/admin/dashboards/smartHome';

//Property imports
import PropertiesOverview from 'views/admin/properties/overview';
import NewProperty from 'views/admin/properties/newProperty';
import EditProperty from 'views/admin/properties/editProperty';
import PropertyPriority from 'views/admin/properties/priority';

// NFT Imports
import NFTMarketplace from 'views/admin/nfts/marketplace';
import NFTPage from 'views/admin/nfts/page';
import NFTCollection from 'views/admin/nfts/collection';
import NFTProfile from 'views/admin/nfts/profile';

// Main Imports
import AccountBilling from 'views/admin/main/account/billing';
import AccountApplications from 'views/admin/main/account/application';
import AccountInvoice from 'views/admin/main/account/invoice';
import AccountSettings from 'views/admin/main/account/settings';
import AccountAllCourses from 'views/admin/main/account/courses';
import AccountCoursePage from 'views/admin/main/account/coursePage';

import UserNew from 'views/admin/main/users/newUser';
import UsersOverview from 'views/admin/main/users/overview';
import UsersReports from 'views/admin/main/users/reports';
import EditUser from 'views/admin/main/users/editUser';

import ProfileSettings from 'views/admin/main/profile/settings';
import ProfileOverview from 'views/admin/main/profile/overview';
import ProfileNewsfeed from 'views/admin/main/profile/newsfeed';

import ApplicationsKanban from 'views/admin/main/applications/kanban';
import ApplicationsDataTables from 'views/admin/main/applications/dataTables';
import ApplicationsCalendar from 'views/admin/main/applications/calendar';

import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
import EcommerceProductOverview from 'views/admin/main/ecommerce/overviewProduct';
import EcommerceProductSettings from 'views/admin/main/ecommerce/settingsProduct';
import EcommerceProductPage from 'views/admin/main/ecommerce/pageProduct';
import EcommerceOrderList from 'views/admin/main/ecommerce/orderList';
import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';
import EcommerceReferrals from 'views/admin/main/ecommerce/referrals';

// Others
import OthersNotifications from 'views/admin/main/others/notifications';
import OthersPricing from 'views/admin/main/others/pricing';
import OthersError from 'views/admin/main/others/404';
import Messages from 'views/admin/main/others/messages';

// Auth Imports
import ForgotPasswordCentered from 'views/auth/forgotPassword/ForgotPasswordCentered.jsx';
import ForgotPasswordDefault from 'views/auth/forgotPassword/ForgotPasswordDefault.jsx';
import LockCentered from 'views/auth/lock/LockCentered.jsx';
import LockDefault from 'views/auth/lock/LockDefault.jsx';
import SignInCentered from 'views/auth/signIn/SignInCentered.jsx';
import SignInDefault from 'views/auth/signIn/SignInDefault.jsx';
import SignUpCentered from 'views/auth/signUp/SignUpCentered.jsx';
import SignUpDefault from 'views/auth/signUp/SignUpDefault.jsx';
import VerificationCentered from 'views/auth/verification/VerificationCentered.jsx';
import VerificationDefault from 'views/auth/verification/VerificationDefault.jsx';

import DeveloperOverview from 'views/admin/main/developers/overview';
import DeveloperNew from 'views/admin/main/developers/newDeveloper';
import EditDeveloper from 'views/admin/main/developers/editDeveloper';

import PropertyTypesOverview from 'views/admin/main/property-types/overview';
import PropertyTypeNew from 'views/admin/main/property-types/newPropertyType';
import EditPropertyType from 'views/admin/main/property-types/editPropertyType';

import StandoutAmenitiesOverview from 'views/admin/main/standout-amenities/overview';
import StandoutAmenitiesNew from 'views/admin/main/standout-amenities/newStandoutAmenities';
import EditStandoutAmenities from 'views/admin/main/standout-amenities/editStandoutAmenities';

import BrokerOverview from 'views/admin/brokers/overview';
import BrokerNew from 'views/admin/brokers/newDeveloper';
import EditBroker from 'views/admin/brokers/editDeveloper';

import BrokerageOverview from 'views/admin/brokerage/overview';
import BrokerageNew from 'views/admin/brokerage/new';
import EditBrokerage from 'views/admin/brokerage/edit';

import OwnerOverview from 'views/admin/owner/overview';
import OwnerNew from 'views/admin/owner/new';
import EditOwner from 'views/admin/owner/edit';

import BusinessOverview from 'views/admin/business/overview';
import BusinessNew from 'views/admin/business/new';
import EditBusiness from 'views/admin/business/edit';

import StoryOverview from 'views/admin/story/overview';

const routes = [
  // --- Dashboards ---
  {
    name: 'Dashboards',
    path: '/dashboards',
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Main Dashboard',
        layout: '/admin',
        path: '/dashboards/default',
        component: <DashboardsDefault />,
      },
      {
        name: 'Car Interface',
        layout: '/admin',
        path: '/dashboards/car-interface',
        component: <DashboardsCarInterface />,
      },
      {
        name: 'Smart Home',
        layout: '/admin',
        path: '/dashboards/smart-home',
        component: <DashboardsSmartHome />,
      },
      {
        name: 'RTL',
        layout: '/rtl',
        path: '/dashboards/rtl',
        component: <DashboardsRTLDefault />,
      },
    ],
  },

  // --- Properties ---
  {
    name: 'Properties',
    path: '/properties',
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'New Property',
        layout: '/admin',
        path: '/properties/new-property',
        exact: false,
        component: <NewProperty />,
      },
      {
        name: 'Overview',
        layout: '/admin',
        path: '/properties/overview', 
        component: <PropertiesOverview />,
      },
      {
        name: 'Test property sorting',
        layout: '/admin',
        path: '/properties/priority',
        component: <PropertyPriority />,
      },
      {
        layout: '/admin',
        path: '/properties/edit-property/:id',
        exact: false,
        component: <EditProperty />
      }
    ],
  },

  {
    name: 'Property Types',
    path: '/property-types',
    icon: <Icon as={MdDomain} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'New Type',
        layout: '/admin',
        path: '/property-types/new-type',
        exact: false,
        component: <PropertyTypeNew />,
      },
      {
        name: 'Types Overview',
        layout: '/admin',
        path: '/property-types/overview',
        exact: false,
        component: <PropertyTypesOverview />,
      },
      {
        layout: '/admin',
        path: '/property-types/edit-property-type/:id',
        exact: false,
        component: <EditPropertyType />
      }
    ],
  },

  {
    name: 'Standout Amenities',
    path: '/standout-amenities',
    icon: <Icon as={MdStore} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'New Amenities',
        layout: '/admin',
        path: '/standout-amenities/new-amenity',
        exact: false,
        component: <StandoutAmenitiesNew />,
      },
      {
        name: 'Amenities Overview',
        layout: '/admin',
        path: '/standout-amenities/overview',
        exact: false,
        component: <StandoutAmenitiesOverview />,
      },
      {
        layout: '/admin',
        path: '/standout-amenities/edit-amenities/:id',
        exact: false,
        component: <EditStandoutAmenities />
      }
    ],
  },

  // Agent Page
  {
    name: 'Agents',
    path: 'agents',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'New Agent',
        layout: '/admin',
        path: '/agents/new-agent',
        exact: false,
        component: <UserNew />,
      },
      {
        name: 'Agents Overview',
        layout: '/admin',
        path: '/agents/agent-overview',
        exact: false,
        component: <UsersOverview />,
      },
      {
        layout: '/admin',
        path: '/agents/edit-agent/:id',
        exact: false,
        component: <EditUser />
      }
      // {
      //   name: 'Users Reports',
      //   layout: '/admin',
      //   path: '/main/users/users-reports',
      //   exact: false,
      //   component: <UsersReports />,
      // },
    ],
  },

  // Brokers Page
  {
    name: 'Brokers',
    path: 'broker',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      // {
      //   name: 'New Broker',
      //   layout: '/admin',
      //   path: '/broker/new',
      //   exact: false,
      //   component: <BrokerNew />,
      // },
      {
        name: 'Brokers Overview',
        layout: '/admin',
        path: '/broker/overview',
        exact: false,
        component: <BrokerOverview />,
      },
      {
        layout: '/admin',
        path: '/broker/edit/:id',
        exact: false,
        component: <EditBroker />
      }
    ],
  },

  // Developer Page
  {
    name: 'Developers',
    path: 'developers',
    icon: <Icon as={MdPerson} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'New Developer',
        layout: '/admin',
        path: '/developers/new-developer',
        exact: false,
        component: <DeveloperNew />,
      },
      {
        name: 'Developers Overview',
        layout: '/admin',
        path: '/developers/developer-overview',
        exact: false,
        component: <DeveloperOverview />,
      },
      {
        layout: '/admin',
        path: '/developers/edit-developer/:id',
        exact: false,
        component: <EditDeveloper />
      }
    ],
  },

  // Brokerage Page
  {
    name: 'Brokerage',
    path: 'brokerages',
    icon: <Icon as={FaHandshake} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'New Brokerage',
        layout: '/admin',
        path: '/brokerage/new',
        exact: false,
        component: <BrokerageNew />,
      },
      {
        name: 'Brokerage Overview',
        layout: '/admin',
        path: '/brokerage/overview',
        exact: false,
        component: <BrokerageOverview />,
      },
      {
        layout: '/admin',
        path: '/brokerage/edit/:id',
        exact: false,
        component: <EditBrokerage />
      }
    ],
  },

  // Business Page
  {
    name: 'Business',
    path: 'business',
    icon: <Icon as={HiLightBulb} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'New Business',
        layout: '/admin',
        path: '/business/new',
        exact: false,
        component: <BusinessNew />,
      },
      {
        name: 'Business Overview',
        layout: '/admin',
        path: '/business/overview',
        exact: false,
        component: <BusinessOverview />,
      },
      {
        layout: '/admin',
        path: '/business/edit/:id',
        exact: false,
        component: <EditBusiness />
      }
    ],
  },

  // Owners Page
  {
    name: 'Owners',
    path: 'owner',
    icon: <Icon as={FaUserTie} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'New Owner',
        layout: '/admin',
        path: '/owner/new',
        exact: false,
        component: <OwnerNew />,
      },
      {
        name: 'Owner Overview',
        layout: '/admin',
        path: '/owner/overview',
        exact: false,
        component: <OwnerOverview />,
      },
      {
        layout: '/admin',
        path: '/owner/edit/:id',
        exact: false,
        component: <EditOwner />
      }
    ],
  },

  {
    name: 'Stories Overview',
    path: 'stories',
    icon: <Icon as={MdOutlineHistoryToggleOff} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Stories Overview',
        layout: '/admin',
        path: '/stories/overview',
        exact: false,
        component: <StoryOverview />,
      },
    ],
  },

  // // --- Main pages ---
  {
    name: 'Main Pages',
    path: '/main',
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      {
        name: 'Account',
        path: '/main/account',
        collapse: true,
        items: [
          {
            name: 'Billing',
            layout: '/admin',
            path: '/main/account/billing',
            exact: false,
            component: <AccountBilling />,
          },
          {
            name: 'Application',
            layout: '/admin',
            path: '/main/account/application',
            exact: false,
            component: <AccountApplications />,
          },
          {
            name: 'Invoice',
            layout: '/admin',
            path: '/main/account/invoice',
            exact: false,
            component: <AccountInvoice />,
          },
          {
            name: 'Settings',
            layout: '/admin',
            path: '/main/account/settings',
            exact: false,
            component: <AccountSettings />,
          },
          {
            name: 'All Courses',
            layout: '/admin',
            path: '/main/account/all-courses',
            exact: false,
            component: <AccountAllCourses />,
          },
          {
            name: 'Course Page',
            layout: '/admin',
            path: '/main/account/course-page',
            exact: false,
            component: <AccountCoursePage />,
          },
        ],
      },
      {
        name: 'Ecommerce',
        path: '/main/ecommerce',
        collapse: true,
        items: [
          {
            name: 'New Product',
            layout: '/admin',
            path: '/main/ecommerce/new-prodcut',
            exact: false,
            component: <EcommerceNewProduct />,
          },
          {
            name: 'Product Overview',
            layout: '/admin',
            path: '/main/ecommerce/product-overview',
            exact: false,
            component: <EcommerceProductOverview />,
          },
          {
            name: 'Product Settings',
            layout: '/admin',
            path: '/main/ecommerce/settings',
            exact: false,
            component: <EcommerceProductSettings />,
          },
          {
            name: 'Product Page',
            layout: '/admin',
            path: '/main/ecommerce/page-example',
            exact: false,
            component: <EcommerceProductPage />,
          },
          {
            name: 'Order List',
            layout: '/admin',
            path: '/main/ecommerce/order-list',
            exact: false,
            component: <EcommerceOrderList />,
          },
          {
            name: 'Order Details',
            layout: '/admin',
            path: '/main/ecommerce/order-details',
            exact: false,
            component: <EcommerceOrderDetails />,
          },
          {
            name: 'Referrals',
            layout: '/admin',
            path: '/main/ecommerce/referrals',
            exact: false,
            component: <EcommerceReferrals />,
          },
        ],
      },
      {
        name: 'Applications',
        path: '/main/applications',
        collapse: true,
        items: [
          {
            name: 'Kanban',
            layout: '/admin',
            path: '/main/applications/kanban',
            exact: false,
            component: <ApplicationsKanban />,
          },
          {
            name: 'Data Tables',
            layout: '/admin',
            path: '/main/applications/data-tables',
            exact: false,
            component: <ApplicationsDataTables />,
          },
          {
            name: 'Calendar',
            layout: '/admin',
            path: '/main/applications/calendar',
            exact: false,
            component: <ApplicationsCalendar />,
          },
        ],
      },
      {
        name: 'Profile',
        path: '/main/profile',
        collapse: true,
        items: [
          {
            name: 'Profile Overview',
            layout: '/admin',
            path: '/main/profile/overview',
            exact: false,
            component: <ProfileOverview />,
          },
          {
            name: 'Profile Settings',
            layout: '/admin',
            path: '/main/profile/settings',
            exact: false,
            component: <ProfileSettings />,
          },
          {
            name: 'News Feed',
            layout: '/admin',
            path: '/main/profile/newsfeed',
            exact: false,
            component: <ProfileNewsfeed />,
          },
        ],
      },
      {
        name: 'Others',
        path: '/main/others',
        collapse: true,
        items: [
          {
            name: 'Notifications',
            layout: '/admin',
            path: '/main/others/notifications',
            exact: false,
            component: <OthersNotifications />,
          },
          {
            name: 'Pricing',
            layout: '/auth',
            path: '/main/others/pricing',
            exact: false,
            component: <OthersPricing />,
          },
          {
            name: '404',
            layout: '/admin',
            path: '/main/others/404',
            exact: false,
            component: <OthersError />,
          },
          {
            name: 'Messages',
            layout: '/admin',
            path: '/main/others/messages',
            exact: false,
            component: <Messages />,
          },
        ],
      },
    ],
  },
  
  // // --- NFTs ---
  {
    name: 'NFTs',
    path: '/nfts',
    icon: (
      <Icon
        as={MdOutlineShoppingCart}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    collapse: true,
    items: [
      {
        name: 'Marketplace',
        layout: '/admin',
        path: '/nfts/marketplace',
        component: <NFTMarketplace />,
        secondary: true,
      },
      {
        name: 'Collection',
        layout: '/admin',
        path: '/nfts/collection',
        component: <NFTCollection />,
        secondary: true,
      },
      {
        name: 'NFT Page',
        layout: '/admin',
        path: '/nfts/page',
        component: <NFTPage />,
        secondary: true,
      },
      {
        name: 'Profile',
        layout: '/admin',
        path: '/nfts/profile',
        component: <NFTProfile />,
        secondary: true,
      },
    ],
  },
  
  // --- Authentication ---
  {
    name: 'Authentication',
    path: '/auth',
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: 'Sign In',
        path: '/sign-in',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-in/default',
            component: <SignInDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/sign-in/centered',
            component: <SignInCentered />,
          },
        ],
      },
      // --- Sign Up ---
      {
        name: 'Sign Up',
        path: '/sign-up',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/sign-up/default',
            component: <SignUpDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/sign-up/centered',
            component: <SignUpCentered />,
          },
        ],
      },
      // --- Verification ---
      {
        name: 'Verification',
        path: '/verification',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/verification/default',
            component: <VerificationDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/verification/centered',
            component: <VerificationCentered />,
          },
        ],
      },
      // --- Lock ---
      {
        name: 'Lock',
        path: '/lock',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/lock/default',
            component: <LockDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/lock/centered',
            component: <LockCentered />,
          },
        ],
      },
      // --- Forgot Password ---
      {
        name: 'Forgot Password',
        path: '/forgot-password',
        collapse: true,
        items: [
          {
            name: 'Default',
            layout: '/auth',
            path: '/forgot-password/default',
            component: <ForgotPasswordDefault />,
          },
          {
            name: 'Centered',
            layout: '/auth',
            path: '/forgot-password/centered',
            component: <ForgotPasswordCentered />,
          },
        ],
      },
    ],
  },
];

export default routes;
