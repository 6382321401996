import React, { useState, useEffect, useRef } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Flex,useToast } from "@chakra-ui/react";
import Card from "components/card/Card";
import { API_BASE_URL } from "constants/apiConstants";
import axios from 'axios';

export default function ReorderDemo() {
  const [properties,setProperties] = useState([]);
    const toast = useToast()
    const fetchProperties = () => {
      axios.get(API_BASE_URL+'get/properties/orderby/priority',{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        setProperties(response.data.data)
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    }

    const columns = [
        { field: 'property_id', header: 'Id' },
        { field: 'title', header: 'Title' },
        { field: 'listing_type', header: 'Type' },
        { field: 'location_title', header: 'Location' },
        { field: 'user.name', header: 'Agent' },
        { field: 'developer.name', header: 'Developer' },
    ];

    const handleRowOrderChange = async (params) => {
      console.log(54354);
      console.log(params);
      const payload ={
        'dropIndex' : params.dropIndex,
        'value' : params.value
      }

      axios.post(API_BASE_URL+'update/property/row-priority',payload,{
        headers: {
          Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}`,
        }
      })
      .then((response) => {
        setProperties(params.value)
      })
      .catch((error) => {
        toast({ title: error,status: 'error',isClosable: true,position: 'top'})
      })
    };

    useEffect(() => {
        fetchProperties();
    }, []);

    const dynamicColumns = columns.map((col, i) => {
        return <Column key={col.field} columnKey={col.field} field={col.field} header={col.header} />;
    });

    return (
      <Flex direction='column' pt={{ sm: "125px", lg: "75px" }}>
        <Card px='0px'>
            <div className="card">
                <DataTable value={properties} reorderableColumns reorderableRows onRowReorder={handleRowOrderChange} tableStyle={{ minWidth: '50rem' }}>
                    <Column rowReorder style={{ width: '3rem' }} />
                    {dynamicColumns}
                </DataTable>
            </div>
          </Card>
    </Flex>
    );
}
        